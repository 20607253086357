import logo from "./logo.svg";
import "./App.css";
import EncryptText from "./components/EncryptText";
import { GridHoverHero } from "./components/GridHeroHover";
import Page from "./components/ScrollPage";
import TrippyHero from "./components/TrippyStuff";

function App() {
  return (
    // <GridHoverHero />
    <>
      <TrippyHero />
      {/* <Page /> */}
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <EncryptText />
    //   </header>
    // </div>
  );
}

export default App;
