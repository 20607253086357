import { useRef, useState } from "react";
import {
  useMotionValueEvent,
  useTransform,
  useScroll,
  motion,
} from "framer-motion";
import BLogo from "./BLogo";
import EncryptText from "./EncryptText";

const TrippyHero = () => {
  return (
    <section className="bg-white" /*style={{ backgroundColor: "#00c22a" }}*/>
      <Navigation />
      <Hero />
      {/* <div className="grid h-screen place-content-center bg-violet-600 text-sm font-semibold text-white">
        <span>The rest of your website {":)"}</span>
      </div> */}
    </section>
  );
};

const Hero = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const scale = useTransform(
    scrollYProgress,
    [0, 0.65, 0.8, 1],
    [1, 1, 0.9, 1.25]
  );
  const rotate = useTransform(
    scrollYProgress,
    [0, 0.25, 1],
    ["0deg", "0deg", "60deg"]
  );
  const top = useTransform(scrollYProgress, [0, 0.25], ["100%", "0%"]);
  const opacity = useTransform(scrollYProgress, [0, 0.125], [1, 0]);
  const logoScale = useTransform(scrollYProgress, [0, 0.8, 1], [0, 0, 1]);
  const textScale = useTransform(scrollYProgress, [0, 0.9, 1], [0, 0, 1]);

  return (
    <div
      ref={targetRef}
      className="relative z-0 h-[800vh] bg-neutral-200"
      // style={{ backgroundColor: "#00c22a" }}
    >
      <div
        className="sticky top-0 h-screen bg-indigo-600"
        // style={{ backgroundColor: "#00c22a" }}
      >
        <Copy opacity={opacity} />
        <Trippy rotate={rotate} top={top} scale={scale} />
        <OverlayLogo scale={logoScale} textScale={textScale} />
      </div>
    </div>
  );
};

const NUM_SECTIONS = 25;
const PADDING = `${100 / NUM_SECTIONS / 2}vmin`;

const generateSections = (count, color, scale, rotate) => {
  if (count === NUM_SECTIONS) {
    return <></>;
  }

  const nextColor = color === "#4F46E5" ? "white" : "#4F46E5";

  return (
    <Section rotate={rotate} scale={scale} background={color}>
      {generateSections(count + 1, nextColor, scale, rotate)}
    </Section>
  );
};

const Trippy = ({ rotate, scale, top }) => {
  return (
    <motion.div
      style={{ top }}
      className="absolute bottom-0 left-0 right-0 overflow-hidden bg-white"
    >
      {generateSections(0, "#4F46E5", scale, rotate)}
    </motion.div>
  );
};

const Section = ({ background, scale, children, rotate }) => {
  return (
    <motion.div
      className="relative h-full w-full origin-center"
      style={{
        background,
        scale,
        rotate,
        padding: PADDING,
      }}
    >
      {children}
    </motion.div>
  );
};

const Copy = ({ opacity }) => {
  return (
    <motion.div
      style={{ opacity }}
      // Padding top + 56px to accommodate for navbar height
      className="relative flex h-screen flex-col items-center justify-center gap-4 overflow-hidden bg-white p-4 pt-[calc(56px_+_16px)] text-black"
    >
      <h1 className="text-center text-5xl font-kobenhavn font-black md:text-7xl">
        Bytewiz.io
      </h1>
      <p className="text-center text-base md:text-lg font-kobenhavn">
        We build, we hack.
      </p>
      {/* <button className="bg-black px-3 py-1.5 text-base font-semibold uppercase text-white md:text-lg">
        Get started
      </button> */}
      <div className="absolute -left-28 -top-28 h-56 w-56 rotate-45 bg-indigo-600" />
      <div className="absolute -bottom-24 -right-24 h-48 w-48 rotate-45 bg-indigo-600" />
    </motion.div>
  );
};

const Navigation = () => {
  const { scrollY } = useScroll();

  const [hidden, setHidden] = useState(false);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  return (
    <motion.nav
      variants={{
        visible: { y: 0 },
        hidden: { y: "-100%" },
      }}
      animate={hidden ? "hidden" : "visible"}
      transition={{ duration: 0.35, ease: "easeInOut" }}
      className="fixed top-0 z-10 flex h-[56px] w-full items-center justify-between  px-2 text-white"
    >
      {/* SVG from logoipsum */}
      <img
        className="h-auto w-32 fill-white"
        src={require("../assets/logo_bwiz.png")}
        alt="logo"
        style={{ width: 30 }}
      />
      {/* <svg
        width="50"
        height="39"
        viewBox="0 0 50 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-auto w-10 fill-white"
      >
        <path
          d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z"
          stopColor="#000000"
        ></path>
        <path
          d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z"
          stopColor="#000000"
        ></path>
      </svg> */}

      {/* <div className="flex gap-2">
        <button className="px-3 py-1.5 font-semibold uppercase text-white hover:bg-white/20">
          Sign in
        </button>
        <button className="bg-white px-3 py-1.5 font-semibold uppercase text-black">
          Sign up
        </button>
      </div> */}
    </motion.nav>
  );
};

const OverlayLogo = ({ scale, textScale }) => {
  return (
    <>
      <motion.div
        style={{ scale }}
        className="pointer-events-none absolute inset-0 z-[5] grid place-content-center"
      >
        {/* SVG from logoipsum */}
        <img
          className="h-auto w-32 fill-white"
          src={require("../assets/logo_bwiz.png")}
          alt="logo"
          style={{ width: 320 }}
        />
        {/* <BLogo /> */}
        {/* <svg
        width="50"
        height="39"
        viewBox="0 0 50 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-auto w-32 fill-white"
      >
        <path
          d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z"
          stopColor="#000000"
        ></path>
        <path
          d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z"
          stopColor="#000000"
        ></path>
      </svg> */}
      </motion.div>
      <motion.div
        style={{ scale: textScale }}
        className="absolute inset-0 z-[5] grid place-content-center"
      >
        <EncryptText text="_@bytewiz.io" />
      </motion.div>
      <motion.div
        style={{ scale: textScale }}
        className="absolute pointer-events-none bottom-12 inset-0 z-[5] grid place-content-center"
      >
        <p className="text-slate-300">Get in contact</p>
      </motion.div>
    </>
  );
};

export default TrippyHero;
